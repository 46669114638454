// External libraries
import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material"
import Box from "@mui/material/Box"

// Absolute imports
import { type Terminal } from "../../../types/ICurrent"

import { formatAmount } from "../../../utils/formatAmount"
import { formatDate } from "../../../utils/formatDate"
import { CurrentTerminalBoxStyled } from "../../../styles/CurrentTerminalBoxStyled"
import { CurrentTerminalTypographyStyled } from "../../../styles/CurrentTerminalTypographyStyled"

interface TerminalTableProps {
  terminals: Terminal[]
  emptyRows: number
}

export function TerminalsTable({ terminals, emptyRows }: TerminalTableProps) {
  return (
    <Box>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table component="div" sx={{ tableLayout: "fixed" }} stickyHeader={true} size="small">
          <TableBody component="div">
            {terminals.map((terminal, terminalIndex) => {
              return (
                <TableRow component="div" key={`${terminal.terminalName}${terminalIndex}`}>
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "nowrap",
                      paddingLeft: "16px",
                      paddingRight: "16px",
                      flexBasis: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        flexBasis: "100%",
                        paddingLeft: "30px",
                        display: "flex",
                      }}
                    >
                      <CurrentTerminalBoxStyled textAlign="left">
                        <CurrentTerminalTypographyStyled>
                          {terminal.terminalName}
                        </CurrentTerminalTypographyStyled>
                        <CurrentTerminalTypographyStyled variant="subtitle2" color="textSecondary">
                          {formatDate(terminal.cashStatusDateTime)}
                        </CurrentTerminalTypographyStyled>
                      </CurrentTerminalBoxStyled>
                      <CurrentTerminalBoxStyled>
                        <Typography>{terminal.recycler1Count}</Typography>
                      </CurrentTerminalBoxStyled>

                      <CurrentTerminalBoxStyled>
                        <Typography>{terminal.recycler2Count}</Typography>
                      </CurrentTerminalBoxStyled>

                      <CurrentTerminalBoxStyled>
                        <Typography>{terminal.stackerCount}</Typography>
                      </CurrentTerminalBoxStyled>
                      <CurrentTerminalBoxStyled>
                        <Typography>
                          <span className="hidden sm:block">
                            {formatAmount(terminal.stackerValue).full}
                          </span>
                          <span className="block sm:hidden">
                            {formatAmount(terminal.stackerValue).short}
                          </span>
                        </Typography>
                      </CurrentTerminalBoxStyled>
                    </Box>
                  </Box>
                </TableRow>
              )
            })}
            {emptyRows > 0 &&
              Array.from({ length: emptyRows }, (_, i) => (
                <TableRow style={{ height: 33 }} key={`emptyRow-${i}`}>
                  <TableCell colSpan={5} />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  )
}
