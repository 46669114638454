export function News() {
  return (
    <div className="pt-1 max-w-lg mx-auto mb-10">
      <h1 className="text-center text-2xl font-bold">News</h1>
      <div className="grid grid-cols-2 sm:grid-cols-2 gap-2 max-w-[480px] mx-auto px-5 mt-5">
        {/* News content will go here */}
        <p className="text-center">News content coming soon!</p>
      </div>
    </div>
  )
}
