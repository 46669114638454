import { type FlattenConfig } from "../utils/flattenExport"
import { formatPhoneNumber } from "../utils/formatPhoneNumber"

export const performanceDetailedConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent.businessName,
      Phone: (_, parent) => formatPhoneNumber(parent.phone),
      "Secondary Phone": (_, parent) => formatPhoneNumber(parent.secondaryPhone),
      Address: (_, parent) => parent.street,
      City: (_, parent) => parent.city,
      "State/Province": (_, parent) => parent.locality,
      Country: (_, parent) => parent.country,
      "Zip/Postal": (_, parent) => parent.zipPostal,
      "Location Number": "locationId",
      Status: "statusMsg",
      Product: "softwareName",
    },
  },
  {
    path: ["terminalTypes"],
    fields: {},
  },
  {
    path: ["terminals"],
    fields: {
      "Terminal Name": "name",
      "Terminal Type": "type",
      Play: "play",
      Win: "win",
      Net: "net",
      "Last Updated": "lastUpdated",
    },
  },
]
export const performanceSummaryConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent.businessName,
      "Location Number": "locationId",
      Status: "statusMsg",
      "Total Location Play": "play",
      "Total Location Win": "win",
      "Total Location Net": "net",
      "Last Updated": "lastUpdated",
    },
  },
]
export const currentCashDetailedConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent?.businessName,
      Phone: (_, parent) => formatPhoneNumber(parent.phone),
      "Secondary Phone": (_, parent) => formatPhoneNumber(parent.secondaryPhone),
      Address: (_, parent) => parent.street,
      City: (_, parent) => parent.city,
      "State/Province": (_, parent) => parent.locality,
      Country: (_, parent) => parent.country,
      "Zip/Postal": (_, parent) => parent.zipPostal,
      "Location Number": "locationId",
      Status: "statusMsg",
    },
  },
  {
    path: ["terminals"],
    fields: {
      "Terminal Name": "terminalName",
      "Cash Status Date Time": "cashStatusDateTime",
      "Recycler 1 Count": "recycler1Count",
      "Recycler 1 Value": "recycler1Value",
      "Recycler 2 Count": "recycler2Count",
      "Recycler 2 Value": "recycler2Value",
      "Stacker Count": "stackerCount",
      "Stacker Value": "stackerValue",
    },
  },
]
export const currentCashSummaryConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent?.businessName,
      "Location Number": "locationId",
      Status: "statusMsg",
      "Total Location Recycler 1 Count": "totalLocationRecycler1Count",
      "Total Location Recycler 2 Count": "totalLocationRecycler2Count",
      "Total Location Stacker Count": "totalLocationStackerCount",
      "Total Location Stacker Value": "totalLocationStackerValue",
    },
  },
]
export const cashAdjustmentDetailedConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent.businessName,
      Phone: (_, parent) => formatPhoneNumber(parent.phone),
      "Secondary Phone": (_, parent) => formatPhoneNumber(parent.secondaryPhone),
      Address: (_, parent) => parent.street,
      City: (_, parent) => parent.city,
      "State/Province": (_, parent) => parent.locality,
      Country: (_, parent) => parent.country,
      "Zip/Postal": (_, parent) => parent.zipPostal,
      "Location Number": "locationId",
      Status: "statusMsg",
      Product: "softwareName",
    },
  },
  {
    path: ["terminals"],
    fields: {
      "Terminal Name": "terminalName",
    },
  },
  {
    path: ["transactions"],
    fields: {
      "Date & Time": "dateTime",
      User: "userName",
      Device: "device",
      Type: "type",
      Amount: "amount",
      "Bill Count": "billCount",
    },
  },
]
export const cashAdjustmentSummaryConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent.businessName,
      "Location Number": "locationId",
      Status: "statusMsg",
    },
  },
  {
    path: ["terminals"],
    fields: {
      "Terminal Name": "terminalName",
      "Total Terminal Add": "totalTerminalAdd",
      "Total Terminal Remove": "totalTerminalRemove",
      "Total Terminal Stack": "totalTerminalStack",
    },
  },
]
export const collectionDetailedConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent?.businessName,
      Phone: (_, parent) => formatPhoneNumber(parent.phone),
      "Secondary Phone": (_, parent) => formatPhoneNumber(parent.secondaryPhone),
      Address: (_, parent) => parent.street,
      City: (_, parent) => parent.city,
      "State/Province": (_, parent) => parent.locality,
      Country: (_, parent) => parent.country,
      "Zip/Postal": (_, parent) => parent.zipPostal,
      "Location Number": "locationId",
      Status: "statusMsg",
    },
  },
  {
    path: ["terminals"],
    fields: {
      "Terminal Name": "terminalName",
    },
  },
  {
    path: ["transactions"],
    fields: {
      "Date & Time": "dateTime",
      User: "userName",
      Type: "type",
      "Total Bill Count": "billCount",
      Amount: "amount",
    },
  },
]
export const collectionSummaryConfig: FlattenConfig[] = [
  {
    path: ["locations"],
    fields: {
      "Business Name": (_, parent) => parent?.businessName,
      "Location Number": "locationId",
      Status: "statusMsg",
    },
  },
  {
    path: ["terminals"],
    fields: {
      "Terminal Name": "terminalName",
      "Total Bill Count": "totalTerminalBill",
    },
  },
]
export const periodDetailedConfig: FlattenConfig[] = [
  {
    path: [],
    fields: {
      "Business Name": (data) => data.businessInfo.name,
      Phone: (data) => formatPhoneNumber(data.businessInfo.phone),
      "Secondary Phone": (data) => formatPhoneNumber(data.businessInfo.secondaryPhone),
      Address: (data) => data.businessInfo.street,
      City: (data) => data.businessInfo.city,
      "State/Province": (data) => data.businessInfo.locality,
      Country: (data) => data.businessInfo.country,
      "Zip/Postal": (data) => data.businessInfo.zipPostal,
    },
  },
  {
    path: ["stores"],
    fields: {
      "Location Number": (data) => data.store.id,
      Status: (data) => data.store.statusMsg,
      Product: (data) => data.store.sofwareName,
      "Session Type": (data) => data.store.sessionType,
      "Game Terminals": (data) => data.store.terminalCount,
      "Last Updated": (data) => data.store.lastUpdate,
      "Today Sales": (data) => data.summary.cds,
      "Today Redeem": (data) => data.summary.cdr,
      "Today Net": (data) => data.summary.cdn,
      "This Week Sales": (data) => data.summary.cws,
      "This Week Redeem": (data) => data.summary.cwr,
      "This Week Net": (data) => data.summary.cwn,
      "Last Week Sales": (data) => data.summary.lws,
      "Last Week Redeem": (data) => data.summary.lwr,
      "Last Week Net": (data) => data.summary.lwn,
      "This Month Sales": (data) => data.summary.tms,
      "This Month Redeem": (data) => data.summary.tmr,
      "This Month Net": (data) => data.summary.tmn,
      "Last Month Sales": (data) => data.summary.lms,
      "Last Month Redeem": (data) => data.summary.lmr,
      "Last Month Net": (data) => data.summary.lmn,
    },
  },
]
export const periodSummaryConfig: FlattenConfig[] = [
  {
    path: [],
    fields: {
      "Business Name": (data) => data.businessInfo.name,
    },
  },
  {
    path: ["stores"],
    fields: {
      "Location Number": (data) => data.store.id,
      Status: (data) => data.store.statusMsg,
      "Last Updated": (data) => data.store.lastUpdate,
      "Today Sales": (data) => data.summary.cds,
      "Today Redeem": (data) => data.summary.cdr,
      "Today Net": (data) => data.summary.cdn,
      "This Week Sales": (data) => data.summary.cws,
      "This Week Redeem": (data) => data.summary.cwr,
      "This Week Net": (data) => data.summary.cwn,
      "Last Week Sales": (data) => data.summary.lws,
      "Last Week Redeem": (data) => data.summary.lwr,
      "Last Week Net": (data) => data.summary.lwn,
      "This Month Sales": (data) => data.summary.tms,
      "This Month Redeem": (data) => data.summary.tmr,
      "This Month Net": (data) => data.summary.tmn,
      "Last Month Sales": (data) => data.summary.lms,
      "Last Month Redeem": (data) => data.summary.lmr,
      "Last Month Net": (data) => data.summary.lmn,
    },
  },
]
export const revenueDetailedConfig: FlattenConfig[] = [
  {
    path: ["stores"],
    fields: {
      "Business Name": (_, parent) => parent.name,
      Phone: (data) => formatPhoneNumber(data.phone),
      "Secondary Phone": (data) => formatPhoneNumber(data.secondaryPhone),
      Address: "street",
      City: "city",
      "State/Province": "locality",
      Country: "country",
      "Zip/Postal": "zipPostal",
    },
  },
  {
    path: ["store"],
    fields: {
      "Location Number": "id",
      Status: "statusMsg",
      Product: "sofwareName",
      "Last Updated": (_, parent) => parent.lastUpdate,
      Sales: (_, parent) => parent.summary.s,
      Redeem: (_, parent) => parent.summary.r,
      Net: (_, parent) => parent.summary.n,
    },
  },
]
export const revenueSummaryConfig: FlattenConfig[] = [
  {
    path: ["stores"],
    fields: { "Business Name": (_, parent) => parent.name },
  },
  {
    path: ["store"],
    fields: {
      "Location Number": "id",
      Status: "statusMsg",
      Product: "sofwareName",
      "Last Updated": (_, parent) => parent.lastUpdate,
      Sales: (_, parent) => parent.summary.s,
      Redeem: (_, parent) => parent.summary.r,
      Net: (_, parent) => parent.summary.n,
    },
  },
]
export const salesDetailedConfig: FlattenConfig[] = [
  {
    path: ["stores"],
    fields: {
      "Business Name": (_, parent) => parent.name,
      Phone: (data) => formatPhoneNumber(data.phone),
      "Secondary Phone": (data) => formatPhoneNumber(data.secondaryPhone),
      Address: "street",
      City: "city",
      "State/Province": "locality",
      Country: "country",
      "Zip/Postal": "zipPostal",
    },
  },
  {
    path: ["store"],
    fields: {
      "Location Number": "id",
      Status: "statusMsg",
      Product: "sofwareName",
      "Last Updated": (_, parent) => parent.lastUpdate,
      Sales: (_, parent) => parent.summary.s,
      Free: (_, parent) => parent.summary.f,
      Promo: (_, parent) => parent.summary.p,
      Percentage: (_, parent) => parent.summary.percentage,
    },
  },
]
export const salesSummaryConfig: FlattenConfig[] = [
  {
    path: ["stores"],
    fields: { "Business Name": (_, parent) => parent.name },
  },
  {
    path: ["store"],
    fields: {
      "Location Number": "id",
      Status: "statusMsg",
      Product: "sofwareName",
      "Last Updated": (_, parent) => parent.lastUpdate,
      Sales: (_, parent) => parent.summary.s,
      Free: (_, parent) => parent.summary.f,
      Promo: (_, parent) => parent.summary.p,
      Percentage: (_, parent) => parent.summary.percentage,
    },
  },
]
export const overviewDetailedConfig: FlattenConfig[] = [
  {
    path: [],
    fields: {
      "Business Name": (data) => data.businessInfo.name,
      Phone: (data) => formatPhoneNumber(data.businessInfo.phone),
      "Secondary Phone": (data) => formatPhoneNumber(data.businessInfo.secondaryPhone),
      Address: (data) => data.businessInfo.street,
      City: (data) => data.businessInfo.city,
      "State/Province": (data) => data.businessInfo.locality,
      "Zip/Postal": (data) => data.businessInfo.zipPostal,
      Country: (data) => data.businessInfo.country,
    },
  },
  {
    path: ["locations"],
    fields: {
      "Location Number": "locationId",
      Status: "locationStatus",
      Product: "softwareName",
      Terminals: "terminalCount",
      "Terminal Alert": (data) => (data.terminalAlert === true ? "yes" : "no"),
    },
  },
]
export const overviewSummaryConfig: FlattenConfig[] = [
  {
    path: [],
    fields: {
      "Business Name": (data) => data.businessInfo.name,
    },
  },
  {
    path: ["locations"],
    fields: {
      "Location Number": "locationId",
      Status: "locationStatus",
      Product: "softwareName",
      Terminals: "terminalCount",
      "Terminal Alert": (data) => (data.terminalAlert === true ? "yes" : "no"),
    },
  },
]
export const terminalsDetailedConfig: FlattenConfig[] = [
  {
    path: [],
    fields: {
      "Business Name": (data) => data.businessInfo.name,
      Phone: (data) => formatPhoneNumber(data.businessInfo.phone),
      "Secondary Phone": (data) => data.businessInfo.secondaryPhone,
      Address: (data) => data.businessInfo.street,
      City: (data) => data.businessInfo.city,
      "State/Province": (data) => data.businessInfo.locality,
      Country: (data) => data.businessInfo.country,
      "Zip/Postal": (data) => data.businessInfo.zipPostal,
    },
  },
  {
    path: ["stores"],
    fields: {},
  },
  {
    path: ["store"],
    fields: {
      "Location Number": "id",
      Status: "statusMsg",
      Product: "sofwareName",
      Platform: "platform",
      "Product Type": "productType",
      "Session Type": "sessionType",
      "Skill Type": "skillType",
      "Next Potential Win": "nextPotentialWin",
    },
  },
  {
    path: ["terminals"],
    fields: {
      Name: "name",
      IP: "IP",
      "Bill Validator Status": (data) =>
        data.billValidatorStatus === -1 ||
        data.billValidatorStatus == null ||
        data.billValidatorStatus === ""
          ? "N/A"
          : data.billValidatorStatus,
      "Connect Time": (data) =>
        data.connectTime == null || data.connectTime === "" ? "N/A" : data.connectTime,
      "General Status": (data) =>
        data.generalStatus === -1 || data.generalStatus == null || data.generalStatus === ""
          ? "N/A"
          : data.generalStatus,
      Role: "role",
      "Role Name": "roleDisplayName",
      Status: "status",
      "Terminal Alert": (data) => (data.terminalAlert === true ? "yes" : "no"),
      "Ticket Printer Status": (data) =>
        data.ticketPrinterStatus === -1 ||
        data.ticketPrinterStatus == null ||
        data.ticketPrinterStatus === ""
          ? "N/A"
          : data.ticketPrinterStatus,
      "Voucher Printer Status": (data) =>
        data.voucherPrinterStatus === -1 ||
        data.voucherPrinterStatus == null ||
        data.voucherPrinterStatus === ""
          ? "N/A"
          : data.voucherPrinterStatus,
      "Last Updated": "lastUpdated",
    },
  },
]
export const terminalsSummaryConfig: FlattenConfig[] = [
  {
    path: [],
    fields: {
      "Business Name": (data) => data.businessInfo.name,
    },
  },
  {
    path: ["stores"],
    fields: {},
  },
  {
    path: ["store"],
    fields: {
      "Location Number": "id",
    },
  },
  {
    path: ["terminals"],
    fields: {
      Name: "name",
      Product: (_, parent) => parent.sofwareName,
      IP: "IP",
      Status: "status",
      "Connect Time": (data) =>
        data.connectTime == null || data.connectTime === "" ? "N/A" : data.connectTime,
      "Role Display Name": "roleDisplayName",
      "Terminal Alert": (data) => (data.terminalAlert === true ? "yes" : "no"),
      "Last Updated": "lastUpdated",
    },
  },
]
